
import { Component, Watch } from 'vue-property-decorator';

import Booking from '@/views/Booking.vue';

import { MetaInfo } from 'vue-meta';

import { ActivitiesApi } from '@/api/ActivitiesApi';

@Component({
   metaInfo(): MetaInfo {
      return {
         title: '',
         titleTemplate: 'Check',
      };
   },
})
export default class Check extends Booking {
   items: { [key: string]: any } = {};

   mounted(): void {
      this.getData();
   }

   getData() {
      this.$store.commit('showLoading');
      let query: any = this.$route.query.data;
      query = JSON.parse(query);
      const getNumberFromTime: any = (time: any) => (time != null ? +time.replace(/:/g, '') : '');
      ActivitiesApi.activityGraphic(query)
         .then(result => {
            this.items = Object.values(result).map((value: any) => {
               return {
                  graphics: value.graphics
                     .sort((a: any, b: any) => getNumberFromTime(a.act_time) - getNumberFromTime(b.act_time))
                     .filter((v: any) => Object.keys(v).length !== 0),
                  messages: value.messages,
               };
            });
         })
         .finally(() => this.$store.commit('hideLoading'));
   }
}
